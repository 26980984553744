<template>
	<div id="fulltestpanel">
		<strong>{{$t('testPanel.title')}}</strong><br>
		<p class="small">{{$t('testPanel.text')}}</p>
		<ul>
			<li @click="clientGoto('beforeStart')">{{$t('testPanel.pages.before')}}</li>
			<li @click="clientGoto('actionEnded')">{{$t('testPanel.pages.after')}}</li>
			<li @click="clientGoto('maxReached')">{{$t('testPanel.pages.maxparticip')}}</li>
			<li @click="clientGoto('wrongcode')">{{$t('testPanel.pages.wrongcode')}}</li>
			<li @click="clientGoto('participated')">{{$t('testPanel.pages.alreadysent')}}</li>
			<li @click="clientGoto('maintenance')">{{$t('testPanel.pages.maintenance')}}</li>
			<li @click="clientGoto('loading')">{{$t('testPanel.pages.loading')}}</li>
		</ul>
		<br>
		<button @click="hideMe()"><span v-show="amIDeployed">{{$t('testPanel.hide')}}</span><span v-show="!amIDeployed">{{$t('testPanel.show')}}</span></button>
	</div>
</template>

<!-- ================================================================================== -->

<script>
	export default {
		name:"full-test-panel",
		data(){
			return {
				amIDeployed: false,
			}
		},
		methods: {
			hideMe(){
				this.amIDeployed = !this.amIDeployed;
				console.log("I received the instruction to toggle the TEST PANEL");
				/* emit "swap" for the form to know it must (un)hide the panel */
				this.$emit("swap");
			},
			clientGoto(dest){
				console.log("I must go to another castle : " + dest);
				/* emit "iWantToGot" + destination as param */
				this.$emit('clientWantToGoThere', dest);
			}
		}
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
