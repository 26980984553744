<template>
	<div>
		<h2>{{$t('page404.title')}}</h2>
		<div style="text-align:center;">
			<p>{{$t('page404.text')}}</p>
			<button @click.prevent="backToTheForm">{{$t('page404.back')}}</button>
		</div>
	</div>
</template>

<!-- ================================================================================== -->

<script>
	import config from "../config";

	export default {
		name:"NotFound",

		components: {
		},

		methods: {
			getKeyFromUrlOnPage404() {
				let key = '';
				let urlParams = new URLSearchParams(window.location.search);
				if (urlParams.has('key')){
					key = urlParams.get('key');
				}
				return key;
			},

			backToTheForm(){
				let link = config.assetPath;
				let key = '?key=' + this.getKeyFromUrlOnPage404();
				window.location = link + key;
			},
		}
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>

</style>
