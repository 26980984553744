<template>
	<div class="spacer" :class="{'neutral' : (h===0), 'small' : (h===1), 'big' : (h===2)}">
		
	</div>
</template>

<!-- ================================================================================== -->

<script>
	export default {
		name:"Spacer",
		props: {
			h : {
				type: Number,
				required: false,
				default: 0
			}
		}
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
.spacer {
	opacity: 0;
	color: transparent;
	
	&.neutral {
		height: 0;
	}
	&.small {
		height: 1.5em;
	}
	&.big {
		height: 3em;
	}
}
</style>
